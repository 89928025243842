import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../variables';
import { useImage } from '../components/ImageContext';
import '../assets/styles/style.css';

const Destinations = () => {
  const [destinations, setDestinations] = useState([]);
  const navigate = useNavigate();
  const { getImageUrl } = useImage();

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const response = await axios.get(`${API_URL}/destination/getAllDestinations`);
        setDestinations(response.data);
      } catch (error) {
        console.error('Error fetching destinations:', error);
      }
    };

    fetchDestinations();
  }, []);

  const handleCardClick = (country) => {
    navigate(`/tours?country=${country}`);
  };

  return (
    <section className="destinations">
      <div className="container">
        <h2>Destinations</h2>
        <div className="card-container" id="destinations">
          {destinations.map((destination) => (
            <div key={destination.id} className="card" onClick={() => handleCardClick(destination.destination_name)}>
              <img src={getImageUrl(destination.destination_image)} alt={destination.destination_name} />
              <div className="card-title">{destination.destination_name}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Destinations;
