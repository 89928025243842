import React, { useState, useEffect } from "react";
import "../assets/styles/style.css"; // 필요한 경우 CSS 경로 수정
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../variables";
import { getToken } from '../authService';  
import { useImage } from "../components/ImageContext";

const api_url = `${API_URL}/package/getAllPackages`;

const PopularTours = () => {
  const [tours, setTours] = useState([]);
  const { getImageUrl } = useImage();

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const token = getToken();
        const response = await axios.get(api_url, {
          headers: {
            'Authorization': `Bearer ${token}`,  
            'Content-Type': 'application/json'
          },
        });

        const validTours = response.data.filter((tour) => 
          tour.package_title && tour.package_location && tour.package_price
        ).map((tour) => ({
          id: tour.package_id,
          title: tour.package_title,
          image: tour.package_image,
          summary: tour.package_summary,
          location: tour.package_location,
          country: tour.package_country,
          price: tour.package_price,
          days: tour.package_days,
          special: tour.package_special || "N/A",
          bestPriceGuarantee: tour.best_price_guarantee,
          freeCancelation: tour.free_cancelation,
          isPopular: tour.is_popular
        }));

        setTours(validTours);
      } catch (error) {
        console.error("Error fetching tour packages:", error.response ? error.response.data : error.message);
      }
    };

    fetchTours();
  }, []);

  return (
    <section id="popular-tours" className="popular-tours-section">
      <div className="container">
        <div className="p-tour-list">
          {tours.map((tour) => (
            <Link
              to={`/tours/${tour.id}`}
              className="p-tour-link"
              key={tour.id}
            >
              <div className="p-tour-card">
                <img
                  src={getImageUrl(tour.image)}
                  alt={tour.title}
                  className="p-tour-image"
                />
                <div className="p-tour-info">
                  <p className="p-tour-location">{tour.location}</p>
                  <h3 className="p-tour-title">{tour.title}</h3>
                  <p className="p-tour-days">{tour.days}<span> days</span></p>
                  <p className="p-tour-price"><span>From $</span>{tour.price}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PopularTours;
