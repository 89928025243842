import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { useImage } from '../components/ImageContext';
import '../assets/styles/style.css';

const Activities = () => {
  const [activities, setActivities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getImageUrl } = useImage();

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get(`${API_URL}/activity/getAllActivities`);
        const sortedActivities = response.data.sort((a, b) => a.activity_order - b.activity_order);
        setActivities(sortedActivities);
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
    };

    fetchActivities();
  }, []);

  const handleViewAllClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="section activities">
      <div className="activities-header">
        <h2>Activities</h2>
        <button className="view-all-button" onClick={handleViewAllClick}>
          View All
        </button>
      </div>

      <div className="list-container">
        {activities.slice(0, 6).map((activity) => ( 
          <div key={activity.id} className="list">
            <img src={getImageUrl(activity.activity_image)} alt={activity.activity_name} />
            <div className="list-title">{activity.activity_name}</div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-icon" onClick={handleCloseModal}>
              &times;
            </span>
            <div className="modal-grid">
              {activities.map((activity) => (
                <div key={activity.id} className="modal-list">
                  <img src={getImageUrl(activity.activity_image)} alt={activity.activity_name} />
                  <div className="list-title">{activity.activity_name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Activities;
