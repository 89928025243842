import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../assets/styles/tourDetails.css"; // Ensure path is correct
import Header from "../components/Header";
import PopularTours from "../components/PopularTours";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import SurveyForm from "../components/SurveyForm";
import axios from 'axios';
import { useImage } from "../components/ImageContext";
import { API_URL } from "../variables";
import Loader from "../components/Loader";

const api_url = `${API_URL}/package/getAllPackages`;

const TourDetails = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [details, setDetails] = useState(null);
  const [itinerary, setItinerary] = useState([]);
  const [inclusions, setInclusions] = useState([]);
  const [exclusions, setExclusions] = useState([]);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const { getImageUrl } = useImage();

  useEffect(() => {
    const fetchTourDetails = async () => {
      try {
        const response = await axios.get(api_url);
        const tours = response.data;
        const tour = tours.find(tour => tour.package_id === id);

        if (tour) {
          setDetails({
            title: tour.package_title,
            summary: tour.package_summary,
            location: tour.package_location,
            price: tour.package_price,
            days: tour.package_days,
            nights: tour.package_nights,
            maxPeople: tour.package_maxpeople,
            minAge: tour.package_minage,
            requirements: tour.package_requirements,
            image: tour.package_image,
          });
          setItinerary(tour.package_itinerary || []);
          setInclusions(tour.package_inclusions || []);
          setExclusions(tour.package_exclusions || []);
          setImages(tour.package_images || []);
        } else {
          setError('Tour not found.');
        }
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load tour details.');
        setIsLoading(false);
      }
    };

    fetchTourDetails();
  }, [id]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handleGalleryOpen = () => setIsGalleryOpen(true);
  const handleGalleryClose = () => setIsGalleryOpen(false);
  const handleImageClick = (image) => setSelectedImage(image);
  const handleCloseImage = () => setSelectedImage(null);

  const getImageStyle = (imageCount) => {
    const flexBasis = 100 / Math.min(imageCount, 5);
    return { flex: `1 1 ${flexBasis}%`, maxHeight: '300px' };
  };

  return (
    <>
      <Header />
      <div className="tour-details">
        <h1>{details.title}</h1>
        <p>{details.summary}</p>

        <div className="image-gallery">
          {images.slice(0, 5).map((image, index) => (
            <img
              key={image}
              src={getImageUrl(image)}
              alt={`Gallery image ${index + 1}`}
              onClick={() => handleImageClick(image)}
              className="gallery-image"
              style={getImageStyle(images.length)}
            />
          ))}
          {images.length > 5 && (
            <button className="gallery-button" onClick={handleGalleryOpen}>
              +{images.length - 5}
            </button>
          )}
        </div>

        {isGalleryOpen && (
          <div className="gallery-modal">
            <div className="gallery-content">
              <button className="close-button" onClick={handleGalleryClose}>
                &times;
              </button>
              {images.map((image) => (
                <img
                  key={image}
                  src={getImageUrl(image)}
                  alt={`Gallery image`}
                  onClick={() => handleImageClick(image)}
                />
              ))}
            </div>
          </div>
        )}

        {selectedImage && (
          <div className="image-modal">
            <div className="image-content">
              <button className="close-button" onClick={handleCloseImage}>
                &times;
              </button>
              <img
                src={getImageUrl(selectedImage)}
                alt={details.title}
              />
            </div>
          </div>
        )}

        <div className="main-content">
          <div className="itinerary">
            <h2>ITINERARY</h2>
            <ul>
              {Array.isArray(itinerary) &&
                itinerary.map((item, idx) => (
                  <li key={idx}>
                    <h3 className="day">{item.title}</h3>
                    <div className="content" dangerouslySetInnerHTML={{ __html: item.content }} />
                  </li>
                ))}
            </ul>
          </div>

          <div className="sidebar">
            <div className="inclusions">
              <h2>Inclusions</h2>
              <ul>
                {inclusions.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>

            <div className="exclusions">
              <h2>Exclusions</h2>
              <ul>
                {exclusions.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
            <SurveyForm packageId={id} />
          </div>
        </div>
      </div>
      <div className="container">
        <Banner />
        <h2>You might also like</h2>
      </div>
      <PopularTours />
      <Footer />
    </>
  );
};

export default TourDetails;
